import merge from "lodash.merge";
const defaultIntegrationConfig = {
        name: 'Default',
        addOns: {
          adoptionFlag: {}
        },
        base: {
          debug: true,
          addOns: () => window._fitAnalytics.addOns,
          shop: {
            prefix: 'boss',
            country: {
              selector: 'meta[name="country"]',
              attr: 'content'
            },
            language: {
              selector: 'meta[name="language"]',
              attr: 'content'
            },
            userLanguage: {
              selector: 'meta[name="user-language"]',
              attr: 'content'
            },
            userId: {
              selector: 'meta[name="user-id"]',
              attr: 'content'
            },
            rootDomain: {
              selector: 'meta[name="mock-root-domain"]',
              attr: 'content'
            },
            blockedLocales: ['fi', 'ru', 'xx'],
            prefetch: false
          },

          scope: '.testCase',
          product: {
            container: '.product',

            hasManufacturedSizes: true,

            currentSerial: {
              selector: 'meta[name="product-id"]',
              attr: 'content'
            },

            thumbnail: {
              selector: '.img-thumbnail',
              attr: 'src'
            },

            addToCart: 'button.add-to-cart',

            sizes: {
              items: '.sizes button',
              code: {
                selector: '.',
                prop: 'value'
              },
              isAvailable: {
                selector: '.',
                is: ':not([disabled])'
              },
              isSelected: {
                selector: '.',
                is: '.selected'
              },
              shouldSelectRecommended: true
            }
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after'
          },

          sizeChartButton: {
            elem: '.size-chart-button',
            shouldHide: true
          }
        }
      },

      experimentInControl = merge({}, defaultIntegrationConfig, {
        name: 'AF in Control',
        base: {
          mockExperiments: {
            'AB': 'control'
          },
          experiments: {
            'AB': {
              effects: {
                'fita': null,
                'control': {
                  addOns: null
                }
              }
            }
          }
        }
      }),

      experimentInFita = merge({}, experimentInControl, {
        name: 'AF in Fita',
        base: {
          mockExperiments: {
            'AB': 'fita'
          }
        }
      }),
      germanLocale = merge({}, defaultIntegrationConfig, {
        name: 'DE language', // the de language has informal copies as opposed to the other locales
        base: {
          shop: {
            userLanguage: null
          }
        }
      }),
      esmIntegration = merge({}, defaultIntegrationConfig, {
        name: 'ESM Integration',
        isEsm: true
      }),

      integrationConfigs = [ defaultIntegrationConfig, experimentInControl, experimentInFita, germanLocale, esmIntegration ];

export { integrationConfigs, defaultIntegrationConfig };
