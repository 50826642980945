import merge from "lodash.merge";

const defaultIntegrationConfig = {
        name: 'Default: hide SC buttons',
        base: {
          debug: true,
          scope: '.testCase',
          shop: {
            prefix: 'boss',
            country: {
              selector: 'meta[name="country"]',
              attr: 'content'
            },
            language: {
              selector: 'meta[name="language"]',
              attr: 'content'
            },
            userLanguage: {
              selector: 'meta[name="user-language"]',
              attr: 'content'
            },
            rootDomain: {
              selector: 'meta[name="mock-root-domain"]',
              attr: 'content'
            },
            prefetch: false
          },

          product: {
            container: '.row',

            hasManufacturedSizes: true,

            currentSerial: {
              selector: 'meta[name="product-id"]',
              attr: 'content'
            },

            thumbnail: {
              selector: '.img-thumbnail',
              attr: 'src'
            },

            addToCart: 'button.add-to-cart',

            sizes: {
              items: '.sizes button',
              code: {
                selector: '.',
                prop: 'value'
              },
              isAvailable: {
                selector: '.',
                is: ':not([disabled])'
              },
              isSelected: {
                selector: '.',
                is: '.selected'
              },
              shouldSelectRecommended: true
            }
          },

          button: {
            anchor: '.accordion',
            anchorPlacement: 'after'
          },

          sizeChartButton: {
            elem: '.size-chart-button',
            shouldHide: true
          },

          extraSizeChartButtons: [
            {
              key: 'accordionSizeChart',
              node: 'body',
              elem: '.accordion-body .size-chart-button',
              shouldHide: true
            }
          ]
        }
      },
      integrationConfigs = [
        defaultIntegrationConfig,
        merge({}, defaultIntegrationConfig, {
          name: 'show SC buttons',
          base: {
            sizeChartButton: {
              elem: '.size-chart-button',
              shouldHide: false
            },
            extraSizeChartButtons: [
              {
                key: 'accordionSizeChart',
                node: 'body',
                elem: '.accordion-body .size-chart-button',
                shouldHide: false
              }
            ]
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'hide main SC button',
          base: {
            sizeChartButton: {
              elem: '.size-chart-button',
              shouldHide: true
            },
            extraSizeChartButtons: [
              {
                key: 'accordionSizeChart',
                node: 'body',
                elem: '.accordion-body .size-chart-button',
                shouldHide: false
              }
            ]
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'hide extra SC button',
          base: {
            sizeChartButton: {
              elem: '.size-chart-button',
              shouldHide: false
            },
            extraSizeChartButtons: [
              {
                key: 'accordionSizeChart',
                node: 'body',
                elem: '.accordion-body .size-chart-button',
                shouldHide: true
              }
            ]
          }
        })
      ];

export { defaultIntegrationConfig, integrationConfigs };
