import ConfigPane from "./ConfigPane";
import { useDispatch, useSelector } from "react-redux";
import { dismissIntegration, getCaseState, launchIntegration} from "./CaseSlice";
import Meta from "../Meta";
import { getPageConfig } from "../PageConfigSlice";
import { useEffect } from "react";
import { clearIntegration, startIntegration, stopIntegration } from "../../../integration";
import { Container } from "react-bootstrap";
import { integrationConfigs, defaultIntegrationConfig } from '../../../integration_configs/config8';
import { OutfitProduct } from "../OutfitProduct";

function Case8(props={}) {
  const pageConfig = useSelector(getPageConfig),
        dispatch = useDispatch(),
        caseState = useSelector(getCaseState);

  useEffect(() => {
    if (pageConfig.isDefaultIntegration) {
      triggerIntegration(defaultIntegrationConfig);
    }
    return () => {
      dispatch(dismissIntegration());
      stopIntegration();
      clearIntegration();
    };
  }, []);

  const triggerIntegration = (config) => {
    if (caseState.isIntegrationLaunched) return;
    dispatch(launchIntegration());
    startIntegration(config);
  };
  return (
    <>
      <ConfigPane configs={integrationConfigs} />
      <div className='testCase'>
        <Meta {... props} />
        <Container className='outfit'>
          {
            props.products.map((product, index) => {
              return <OutfitProduct key={index} {... product} />;
            })
          }
        </Container>
      </div>
    </>
  );
}

export { Case8 };
