import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import {startIntegration, stopIntegration, clearIntegration} from '../../../integration';
import { getPageConfig } from "../PageConfigSlice";
import {dismissIntegration, launchIntegration, getCaseState} from "./CaseSlice";
import AddToCartButton from "../AddToCartButton";
import SizeChart from "../SizeChart";
import SizesAdvanced from "../SizesAdvanced";
import Thumbnail from "../Thumbnail";
import Title from "../Title";
import ConfigPane from "./ConfigPane";
import Meta from "../Meta";
import { defaultIntegrationConfig, integrationConfigs } from '../../../integration_configs/config16';

function Case16(props = {}) {
  const pageConfig = useSelector(getPageConfig),
        dispatch = useDispatch(),
        caseState = useSelector(getCaseState);

  useEffect(() => {
    if (pageConfig.isDefaultIntegration) {
      triggerIntegration(defaultIntegrationConfig);
    }
    return () => {
      dispatch(dismissIntegration());
      stopIntegration();
      clearIntegration();
    };
  }, []);

  const triggerIntegration = (config) => {
    if (caseState.isIntegrationLaunched) return;
    dispatch(launchIntegration());
    startIntegration(config);
  };

  return (<>
    <ConfigPane configs={integrationConfigs}/>
    <Container className='testCase'>
      <Row>
        <Thumbnail src = {props.thumbnail}/>
        <Col>
          <Meta {... props}/>
          <Title title={props.title}/>
          <p>{props.description}</p>
          <SizesAdvanced sizes={props.sizes}
            availability={4/5}
          />
          <SizeChart />
          <AddToCartButton />
        </Col>
      </Row>
    </Container>
  </>);
}

export { Case16 };
