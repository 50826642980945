import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { dismissIntegration, getCaseState, launchIntegration} from "./CaseSlice";
import { getPageConfig } from "../PageConfigSlice";
import { clearIntegration, startIntegration, stopIntegration } from "../../../integration";
import ConfigPane from "./ConfigPane";
import Meta from "../Meta";
import Title from "../Title";
import Sizes from "../Sizes";
import Thumbnail from "../Thumbnail";
import AddToCartButton from "../AddToCartButton";
import SizeChartButton from "../SizeChart";
import { CountrySelect } from "../CountrySelect";
import { SizingSystems } from "../SizingSystem";
import { integrationConfigs, defaultIntegrationConfig } from "../../../integration_configs/config12";

function Case12(props={}) {
  const pageConfig = useSelector(getPageConfig),
        dispatch = useDispatch(),
        caseState = useSelector(getCaseState),
        selectedSizingSystem = caseState.sizingSystem,
        country = caseState.country,
        sizingSystems = [country || 'US', 'FR'];

  useEffect(() => {
    if (pageConfig.isDefaultIntegration) {
      triggerIntegration(defaultIntegrationConfig);
    }
    return () => {
      dispatch(dismissIntegration());
      stopIntegration();
      clearIntegration();
    };
  }, []);

  const triggerIntegration = (config) => {
    if (caseState.isIntegrationLaunched) return;
    dispatch(launchIntegration());
    startIntegration(config);
  };
  return (
    <>
      <ConfigPane configs={integrationConfigs} />
      <div className='d-flex justify-content-end me-5 mt-3'>
        <CountrySelect countries={['US', 'UK']}/>
      </div>
      <Container className='testCase'>
        <Row>
          <Thumbnail src = {props.thumbnail}/>
          <Col>
            <Meta {... props} country={country}/>
            <Title title={props.title}/>
            <p>{props.description}</p>
            <SizingSystems systems={sizingSystems} />
            <Sizes sizes={props.sizes[selectedSizingSystem]} />
            <SizeChartButton />
            <AddToCartButton />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export { Case12 };
