import ConfigPane from "./ConfigPane";
import ListingPage from "../ListingPage";
import { useDispatch, useSelector } from "react-redux";
import { dismissIntegration, getCaseState, launchIntegration} from "./CaseSlice";
import Meta from "../Meta";
import { getPageConfig } from "../PageConfigSlice";
import { useEffect } from "react";
import { clearIntegration, startIntegration, stopIntegration } from "../../../integration";
import ProductTile from "../ProductTile";
import QuickView from "../Quickview";
import { integrationConfigs, defaultIntegrationConfig } from "../../../integration_configs/config10";

function Case10(props={}) {
  const pageConfig = useSelector(getPageConfig),
        dispatch = useDispatch(),
        caseState = useSelector(getCaseState);

  useEffect(() => {
    if (pageConfig.isDefaultIntegration) {
      triggerIntegration(defaultIntegrationConfig);
    }
    return () => {
      dispatch(dismissIntegration());
      stopIntegration();
      clearIntegration();
    };
  }, []);

  const triggerIntegration = (config) => {
    if (caseState.isIntegrationLaunched) return;
    dispatch(launchIntegration());
    startIntegration(config);
  };
  return (
    <>
      <ConfigPane configs={integrationConfigs} />
      <div className='testCase'>
        <Meta {... props} />
        <ListingPage className='plp' Tile={ProductTile} items={props.products}/>
        <QuickView />
      </div>
    </>
  );
}

export { Case10 };
