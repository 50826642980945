import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {startIntegration, stopIntegration, clearIntegration} from '../../../integration';
import {dismissIntegration, launchIntegration, getCaseState} from "./CaseSlice";
import { getPageConfig } from "../PageConfigSlice";
import merge from 'lodash.merge';

function useIntegration(defaultIntegrationConfig) {
  const pageConfig = useSelector(getPageConfig),
        dispatch = useDispatch(),
        caseState = useSelector(getCaseState);

  useEffect(() => {
    const triggerIntegration = (config) => {
      if (caseState.isIntegrationLaunched) return;
      if (pageConfig.isHighlightActive) {
        config = merge({}, config, {base: {highlight: pageConfig.isHighlightActive}});
      }
      dispatch(launchIntegration());
      startIntegration(config);
    };

    if (pageConfig.isDefaultIntegration) {
      triggerIntegration(defaultIntegrationConfig);
    }

    return () => {
      dispatch(dismissIntegration());
      stopIntegration();
      clearIntegration();
    };
  }, []);
}

export { useIntegration };
