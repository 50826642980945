window._fitAnalytics = (imports) => {
  window.Integration = imports.Integration;
  window.AdoptionFlag = imports.AdoptionFlag;
  window.SizeAlert = imports.SizeAlert;
  window.getAttribute = imports.getAttribute;
};

export const startIntegration = ({base: config , variants, addOns, isEsm}) => {
  if (isEsm) {
    startEsmIntegration({config, variants, addOns});
  } else if (!window.Integration) {
    fetchWidgetPDP()
      .then(() => {
        initIntegration({config, variants, addOns});
      })
      .catch(console.error);
  }
};

const importIntegrationInterface = function () {
  const nodeEnv = process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV;
  switch (nodeEnv) {
    case 'development':
      return import('http://localhost:3000/integration_interface_esm.js');
    case 'staging':
      return import('https://front-staging2.fitanalytics.com/integration_interface_esm.js');
    case 'production':
      return import('https://widget.fitanalytics.com/integration_interface_esm.js');
    default:
      return Promise.reject('No script assigned to this environment:', nodeEnv);
  }
};

const startEsmIntegration = ({config, variants, addOns}) => {
  importIntegrationInterface()
    .then((imports) => {
      _fitAnalytics(imports);
      initIntegration({config, variants, addOns});
    });
};

const initIntegration = ({config, variants, addOns}) => {
  if (addOns) {
    initAddOns(addOns);
  }
  const integration = new window.Integration(config, variants);
  window._fitAnalytics.integration = integration;
  integration.start();
};

const initAddOns = (addOnConfigs = {}) => {
  const addOnMap = {
          adoptionFlag: window.AdoptionFlag,
          msa: window.SizeAlert
        },
        addOns = [];
  for (let addOnName in addOnConfigs) {
    let addOnConfig = addOnConfigs[addOnName];
    const AddOn = addOnMap[addOnName];
    if (AddOn) {
      addOns.push(new AddOn(addOnConfig));
    }
  }
  window._fitAnalytics.addOns = addOns;
};

export const stopIntegration = () => {
  window._fitAnalytics?.integration?.stop();
};

export const clearIntegration = () => {
  window._fitAnalytics?.integration?.unmount();
  delete window?.__fitAnalytics__;
  delete window?.FitAnalyticsWidget;
  delete window._fitAnalytics?.integration;
  delete window._fitAnalytics?.widget;
  delete window._fitAnalytics?.totalfit;
  delete window._fitAnalytics?.addOns;
  delete window.Integration;
  delete window.AdoptionFlag;
  delete window.SizeAlert;
  delete window.getAttribute;
  document.getElementById('widget_pdp')?.remove();
};

const getBase = function () {
  const baseMap = {
          'development': '//localhost:3000',
          'staging': 'https://front-staging2.fitanalytics.com',
          'production': 'https://widget.fitanalytics.com'
        },
        env = process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV;
  return baseMap[env];
};

export const fetchWidgetPDP = function () {
  return new Promise(function (resolve, reject) {
    const base = getBase();
    const scriptElem = document.createElement('script');
    scriptElem.setAttribute('src', `${base}/widget_pdp.js`);
    scriptElem.setAttribute('id', `widget_pdp`);
    scriptElem.setAttribute('type', 'text/javascript');
    scriptElem.onload = resolve;
    document.body.appendChild(scriptElem);
  });
};
